<template>
  <!-- <swiper 
    :spaceBetween="30" 
    :centeredSlides="true" 
    :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
    :pagination='{ "clickable": true }' 
    :navigation="true" 
	:style="{
	  '--swiper-navigation-color': '#ff5a00',
	  '--swiper-pagination-color': '#ff5a00'
	}"
    class="mySwiper"
    >
    <swiper-slide v-for="(item,index) in bannerList" :key="index">
		<div class="banner">
			<img :src="item.resourceLink">
		</div>
	</swiper-slide>
  </swiper> -->
  <div class="section1" style="height: calc(100vh - 350px);">
      <div class="main">
          <a class="bigTitle">
              <h2>公司简介</h2>
              <span>Profile</span>
          </a>
          <div class="conbox">
              <div class="emptybg">
                    <!-- <video width="557" height="388" controls="controls">
                        <source src="@/assets/qipa.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video> -->
              </div>
              <div class="aboutus">
                  <h4>{{ title }}</h4>
				  <!-- <div class="companyDesc" v-html="desc"> -->
				  <div class="companyDesc">
					  <p>广州仙灵网络科技有限公司成立于2019年11月，注册资本为人民币100万元整，是一家集游戏发行、游戏推广，游戏运营，网站经营、管理于一体的综合性公司。我们以“健康游戏、理性游戏、快乐游戏”为宗旨，始终坚持用户至上、用心服务于客户，坚持用自己的服务去打动客户。</p>
					  <p>我们是一家年轻富有创意的游戏运营商，通过自主研发和多平台精细化运营，目前处公司正运营数十款多元化精品化的游戏产品，涉足游戏包括手游、H5、微端、页游等品类，其中多款是业内顶尖产品，为用户提供优质全面的互联网娱乐体验，并得到业界的广泛认可。公司核心成员专注BT游戏发行领域多年，善于搭建手机游戏内的商业化内购模块，以及互联网手机游戏发行的商业化包装，通过打造区别于普通常规市场的手机游戏和新颖玩法吸引玩家，协助游戏研发公司运营并在极短时间内达到商业化变现的效果，目前有数百款重度卡牌/挂机/传奇游戏和MMO游戏，立志成为BT游戏行业的领先企业！
						</p>
				  </div>
              </div>
          </div>
      </div>
  </div>
  
  <!-- <Team /> -->
  <!-- <News /> -->
  <!-- <Games /> -->
  <!-- <Partner /> -->
  
</template>

<script setup>
// @ is an alias to /src
import Team from '@/components/home/Team.vue'
import News from '@/components/home/News.vue'
import Games from '@/components/home/Games.vue'
import Partner from '@/components/home/Partner.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, { Autoplay,Pagination,Navigation } from 'swiper/core';
SwiperCore.use([Autoplay,Pagination,Navigation]);

import { getPartDetail } from '@/api/index'
import { ref, onMounted, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance(); 
const title = ref('关于公司');
const desc = ref('');

const bannerList = ref([])

onMounted(()=>{//1公司简介
	getPartDetail({ ifPage: 0, moduleId: proxy.partten.moduleCompanyInfo }).then((res)=>{
		if(res.rows.length > 0){
			title.value = res.rows[0].title;
			desc.value = res.rows[0].textDescription;
		}
	})
	//14首页轮播图
	getPartDetail({ ifPage: 0, moduleId: proxy.partten.moduleBannerBig }).then((res)=>{
		bannerList.value = res.rows;
	})
})

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.emptybg{
		width: 557px;height: 388px;background: #eee;
	}
.mySwiper{
    width: 100%;height: calc(100vh - 85px);min-height: 560px;
	.banner{
		position: relative;width: 100%;height: 100%;overflow: hidden;
		img{
			height: 100%;position: absolute;
			top: 0;left: 50%;transform: translate(-50%,0);
		}
	}
}
.conbox{
    display: flex;align-items: center;font-size:14px;width:100%;
    .aboutus{
        padding-left: 30px;
        h4{
            font-size: 16px;line-height: 32px;margin-top: 10px;margin-bottom: 15px;
        }
        p,.companyDesc{line-height: 24px;margin-bottom: 15px;text-indent: 2em;}
    }
}
</style>
